// var GqUrl = 'https://testapigateway.upexciseonline.co/';
var GqUrl = 'https://qaapigatewaynewapi.upexciseonline.co/';

export const environment = {
  name: 'qa',
  production: true,
  baseURLMaster: GqUrl + 'master/v1.0.0/',
  baseELotteryURLMaster: GqUrl + 'elotteryService/v1.0.0/',
  baseURL: GqUrl + 'sugarmill/v1.0.0/',
  localDBUrl: 'https://json.upexciseonline.co/',
  baseURLL: GqUrl + 'master/v1.0.0/',
  sugarmillBaseURL: GqUrl + 'sugarmill/v1.0.0/',
  licencemanagementURL: GqUrl + 'licensemanagement/v1.0.0/',
  vehicleTracking: GqUrl + 'vehicleTracking/v1.0.0/api/',
  wholesaleURL: GqUrl + 'wholesale/1.0.0/',
  warehouseURL: GqUrl + 'warehouse/1.0.0/',
  warehouseurl: GqUrl + 'warehouse/1.0.0/',
  distilleryBaseURL: GqUrl + 'distillery/v1.0.0/',
  helpdeskURL: GqUrl + 'helpdesk/v1.0.0/',
  retailURL: GqUrl + 'retailpos/v1.0.0/',
  workflowconfig: GqUrl + 'workflow/v1.0.0/',
  devisionUrl: GqUrl + 'master/v1.0.0/',
  documentUrl: GqUrl + 'docManagement/v1.0.0/',
  documentIPUrl: GqUrl + 'docManagement/v1.0.0/',
  licencemanagementIPURL: GqUrl + 'licensemanagement/v1.0.0/',
  userManagerUrl: GqUrl + 'user-managment/v1.0.0/',
  worflowIPURL: GqUrl + 'workflow/v1.0.0/',
  parentunit: GqUrl + 'parentUnit/1.0/',
  helpdesk: GqUrl + 'helpdesk/v1.0.0/',
  notificationURL: GqUrl + 'notification/v1.0.0/',
  notificationURLnew: GqUrl+'notification/',
  baseURLMasterIpAddr: GqUrl + 'master/v1.0.0/',
  scmproduction: GqUrl + 'scmProduction/v1.0.0/',
  scmOtherModules: GqUrl + 'scmOtherModules/v1.0.0/',
  licneseBaseUrl: GqUrl + 'licensemanagement/v1.0.0/',
  paymentDomain: GqUrl + 'payment/1.0',
  payuRequest: '/payu/request',
  payuStatusCheck: '/payu/statusCheck',
  payuAction: 'https://test.payu.in/_payment',
  paymentSurl: "https://test.upexciseonline.co/JSP/index.jsp",
  paymentFurl: "https://test.upexciseonline.co/JSP/index.jsp",
  paymentBaseUrl: GqUrl,
  licenseTypeBaseUrl: GqUrl + 'scmProduction/v1.0.0/',
  baseURLUser: GqUrl + 'user-managment/v1.0.0/',
  parentUnit: GqUrl + 'scmProduction/v1.0.0/',
  wholesaleNewURL: GqUrl + 'retailpos/v1.0.0/',
  trackandtraceUrl: GqUrl + 'trackandtrace/v1.0.0/',
  distcmintegrationUrl: GqUrl + 'distcmintegration/v1.0.0/',
  payandreconBaseUrl: GqUrl,
  domainUrl: GqUrl,
  publicportalurl:'https://testcms.upexciseonline.co/',
  misportalurl:'https://mis.upexciseonline.co/dashboard/login',
  licenseManagement: GqUrl + 'licensemanagement/v1.0.0/' + 'license/',
  SSOLoginUrl: 'https://testmis.upexciseonline.co/',
  SSODashoardUrl : 'https://testmis.upexciseonline.co/dashboard/omisystem/customreport',
  // info: 'yuERueyJhdXRoVG9rZW4iOiJiYTE5ZDk0OS05MGEzLTNiMzctOWViOC03ZjRmNjdlN2NhYmEiLCJwYXltZW50U2FsdCI6IjlWSnRMWWlKIiwiZW5jcnlwdGlvbktleSI6Im9hc3lzVVBleGNpc2VLZXlAMTIzISEifQ=='
  info: 'weRTxeyJhdXRoVG9rZW4iOiJCZWFyZXIgZXlKNE5YUWlPaUpOVjBsNVRrUkpOVmxxUlRKYVYxa3hUMFJOZDAxWFNUTk9SMXB0VFZSWmVVNVVUVEpPVkZab1dsUm5NVTVVVFROYVZFNW9UbGRLYlZwRVJURlBWRUUwVGxkRk1WbFVhR3hOYWs1c1RsZEZlbGxxU1hsWlVTSXNJbXRwWkNJNklrMVhTWGxPUkVrMVdXcEZNbHBYV1RGUFJFMTNUVmRKTTA1SFdtMU5WRmw1VGxSTk1rNVVWbWhhVkdjeFRsUk5NMXBVVG1oT1YwcHRXa1JGTVU5VVFUUk9WMFV4V1ZSb2JFMXFUbXhPVjBWNldXcEplVmxSWDFKVE1qVTJJaXdpZEhsd0lqb2lZWFFyYW5kMElpd2lZV3huSWpvaVVsTXlOVFlpZlEuZXlKemRXSWlPaUkwTTJSaVlUVXlOUzB3TVRrMUxUUmpaalF0T1RBek5pMW1NVGN6TXpZeU1EWTBPREFpTENKaGRYUWlPaUpCVUZCTVNVTkJWRWxQVGlJc0ltRjFaQ0k2SWpsSlNHSk1aMUJNY2psbmVUbGFObVpzU1hWa01FOTBNRXh3U1dFaUxDSnVZbVlpT2pFM01qUTVNalF3TmpRc0ltRjZjQ0k2SWpsSlNHSk1aMUJNY2psbmVUbGFObVpzU1hWa01FOTBNRXh3U1dFaUxDSnpZMjl3WlNJNkltUmxabUYxYkhRaUxDSnBjM01pT2lKb2RIUndjenBjTDF3dmNXRmhjR2xuWVhSbGQyRjVibVYzTG5Wd1pYaGphWE5sYjI1c2FXNWxMbU52T2pRME0xd3ZiMkYxZEdneVhDOTBiMnRsYmlJc0ltVjRjQ0k2TVRneE1Ea3lOREEyTkN3aWFXRjBJam94TnpJME9USTBNRFkwTENKcWRHa2lPaUk0TWpoa05qSmlNaTB6WmpSaExUUTRZbU10WVRBek55MDNZakZrTTJJeE5EQXdZVEVpTENKamJHbGxiblJmYVdRaU9pSTVTVWhpVEdkUVRISTVaM2s1V2pabWJFbDFaREJQZERCTWNFbGhJbjAuRlRWQTNNZ3ZVQ2ZucU1INDJSRmdET3ZvdlJETFdCQ2hXMGtLdnNEYUdHY3hWZWYzQk8tU3ZrczNmV2l2bWhXc1U4R082RG1TMkpDMEdfbk9EZmxiSjNVeDg1LXJZS29zajM1MGVzbGZuUUlUNi1SU0cxT3pkbHFwOHExdVkwM1ZqSjZqd1JfVndjSk84eGhZd3JVNVREZGJ5U0hQZHFBd0lZQnVjN3lwR2tLQ3MwQVo5eHpsOWhCRzFRZ0o5TDVUS2xrdFNPTFg4SzByODcwa2hZV09hYUhwODEyNXRPTUNBTzdHNDBNMnFPc0xrQjlNZldIM0V6eVB1NExSa0xCUEZkNjNLZWVWRmVobUEzX1NwcklURlVUTW9FMWMzbllSQ21UU003b1BTaUZsTXVWWHVFS3YyT1dfVXh3WUJuWnpzTlhfZDVtQk50bTBUMC1oSUtibXZBIiwicGF5bWVudFNhbHQiOiI5Vkp0TFlpSiIsImVuY3J5cHRpb25LZXkiOiJvYXN5c1VQZXhjaXNlS2V5QDEyMyEhIn0=',
  tpMapRoutUrl: 'http://125.21.11.171:6001/TReport/VehicleRoute?id=',
  tpLiveMapUrl :'http://125.21.11.171:6001/TReport/LiveOnMap?id='
};
